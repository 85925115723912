import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Table } from 'react-bootstrap';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesGuilds: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Guilds</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_guilds.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Guilds</h1>
          <h2>Guild system in AFK Journey explained.</h2>
          <p>
            Last updated: <strong>15/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Guilds in AFK Journey" />
        <StaticImage
          src="../../../images/afk/generic/guild_1.webp"
          alt="Guides"
        />
        <p>
          Guilds in AFK Journey allow you to play together with your friends by
          doing challenges and progressing through the game, but you also can
          compete against other guilds in Battle Drills and Glory Expedition.{' '}
          <strong className="red">
            What is super important is that unlike in AFK Arena, in AFK Journey,
            guilds are not cross-server.
          </strong>
          . Which means you have to play on the same server as your friends if
          you all want to join the same guild. We're really hoping this will be
          changed for the release (or shortly after) as trying to recruit anyone
          for an old server after let's say a month will be impossible - nobody
          will join an old server where they can't compete in PVP or Dream
          Realm.
        </p>
        <p>Here's everything you need to know about the Guild system:</p>
        <ul>
          <li>
            You need to pass the AFK Stage 30 to unlock Guilds in AFK Journey,
          </li>
          <li>
            Guilds are currently limited to <strong>30 members</strong>,
          </li>
          <li>
            Guilds can be leveled up to level 6 and each level grants the guild
            members some additional benefits (but the 30 member level cap
            doesn't increase),
          </li>
          <li>
            To level up a Guild you will need <strong>Activity</strong>. Each
            Activity obtained by guild members via the weekly quests grants 1
            Guild EXP,
          </li>
          <li>
            Guild Store is the source of various summoning currencies:{' '}
            <AFKItem name="Invite Letter" />{' '}
            <AFKItem name="Epic Invite Letter" /> and{' '}
            <AFKItem name="Stellar Crystal" />,
          </li>
          <li>
            Guild Store is the only place where you can directly buy{' '}
            <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" />{' '}
            characters,
          </li>
          <ul>
            <li>
              While the <strong>Guild Challenges</strong> will grant you a lot
              of Guild Medals early on, once your guild finishes them all, you
              will be limited to Weekly Guild Quests which grant 7000 Guild
              Medals. This means that after that you will be able to buy 1 copy
              of a <AFKItem name="Celestial" /> or <AFKItem name="Hypogean" />{' '}
              character per month,
            </li>
          </ul>
          <li>
            Every week, the members of the Guild will be able to open the Guild
            Chest to get various items, including summoning currencies and
            Essences,
          </li>
          <li>
            If you leave a guild, you will have to wait 30 minutes before being
            able to join another one.
          </li>
        </ul>
        <SectionHeader title="Guild Levels" />
        <p>
          As we mentioned previously, while the{' '}
          <strong>member cap doesn't increase</strong> with the guild levels,
          you still get additional benefits for upgrading the guild level. So
          make sure to keep active players in your guild!
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Guild Level</th>
              <th>Benefits</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>Level 1</strong>
              </td>
              <td>
                <ul>
                  <li>
                    Unlocks Guild Quests and you can earn up to 2500 Guild
                    Medals per week,
                  </li>
                  <li>
                    1x <AFKItem name="Epic Invite Letter" /> added to Guild
                    Store.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 2</strong>
              </td>
              <td>
                <ul>
                  <li>Weekly Guild Quest Medal limit +1000,</li>
                  <li>
                    1x <AFKItem name="Epic Invite Letter" /> added to Guild
                    Store.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 3</strong>
              </td>
              <td>
                <ul>
                  <li>Weekly Guild Quest Medal limit +500,</li>
                  <li>
                    1x <AFKItem name="Epic Invite Letter" /> added to Guild
                    Store.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 4</strong>
              </td>
              <td>
                <ul>
                  <li>Weekly Guild Quest Medal limit +1000,</li>
                  <li>
                    1x <AFKItem name="Epic Invite Letter" /> added to Guild
                    Store.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 5</strong>
              </td>
              <td>
                <ul>
                  <li>Weekly Guild Quest Medal limit +1000,</li>
                  <li>
                    1x <AFKItem name="Epic Invite Letter" /> added to Guild
                    Store.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Level 6</strong>
              </td>
              <td>
                <ul>
                  <li>Weekly Guild Quest Medal limit +1000,</li>
                  <li>
                    1x <AFKItem name="Epic Invite Letter" /> added to Guild
                    Store.
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Guild Challenges" />
        <StaticImage
          src="../../../images/afk/generic/guild_4.webp"
          alt="Guides"
        />
        <p>
          Guild Challenges consist of a series of Quests and to finish them, a
          certain number of Guild Members has to reach specific milestones in
          their account progression. You can obtain a sizable amount of Guild
          Medals for doing the challenges.
        </p>
        <p>
          <strong>15 members</strong> of the guild need to complete the
          challenge for it to be market as completed and in total you can obtain{' '}
          <strong>31 700</strong> Guild Points if you do all of them.
        </p>
        <p>Here's a list of all of the challenges:</p>
        <Table striped bordered responsive className="afk-ascension">
          <thead>
            <tr>
              <th>No.</th>
              <th>Challenge details</th>
              <th>Guild Medals</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#1</td>
              <td>Reach AFK Stage 83</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#2</td>
              <td>Reach AFK Stage 166</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#3</td>
              <td>Reach AFK Stage 250</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#4</td>
              <td>Reach AFK Stage 333</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#5</td>
              <td>Reach AFK Stage 500</td>
              <td className="info">1500</td>
            </tr>
            <tr>
              <td>#6</td>
              <td>Reach Resonance Level 50</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#7</td>
              <td>Reach Resonance Level 100</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#8</td>
              <td>Reach Resonance Level 240</td>
              <td className="info">1500</td>
            </tr>
            <tr>
              <td>#9</td>
              <td>Complete Chapter 1</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#10</td>
              <td>Complete Chapter 2</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#11</td>
              <td>Complete Chapter 3</td>
              <td className="info">1500</td>
            </tr>
            <tr>
              <td>#12</td>
              <td>Reach 1500 points in Arena</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#13</td>
              <td>Reach 2000 points in Arena</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#14</td>
              <td>Defeat Any Boss on Common Difficulty in Dream Realm</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#15</td>
              <td>Defeat Any Boss on Difficult Difficulty in Dream Realm</td>
              <td className="info">1500</td>
            </tr>
            <tr>
              <td>#16</td>
              <td>Defeat Any Boss on Elite Difficulty in Dream Realm</td>
              <td className="info">1500</td>
            </tr>
            <tr>
              <td>#17</td>
              <td>Defeat Any Boss on Hell Difficulty in Dream Realm</td>
              <td className="info">1500</td>
            </tr>
            <tr>
              <td>#18</td>
              <td>Clear Arcane Labyrinth on Difficulty 2</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#19</td>
              <td>Clear Arcane Labyrinth on Difficulty 5</td>
              <td className="info">1500</td>
            </tr>
            <tr>
              <td>#20</td>
              <td>Clear Floor 10 on All Legend Trial Towers</td>
              <td className="info">1400</td>
            </tr>
            <tr>
              <td>#21</td>
              <td>Clear Floor 45 on All Legend Trial Towers</td>
              <td className="info">1500</td>
            </tr>
            <tr>
              <td>#22</td>
              <td>Clear Floor 80 on All Legend Trial Towers</td>
              <td className="info">1500</td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Guild Chest" />
        <StaticImage
          src="../../../images/afk/generic/guild_2.webp"
          alt="Guides"
        />
        <p>
          Every week, the members of the Guild will be able to open the Guild
          Chest to get various items, including summoning currencies and
          Essences. Here's how the Guild Chest system works:
        </p>
        <ul>
          <li>
            You can earn Guild Chest for the Guild by doing weekly Guild Quests,
          </li>
          <li>
            You can also earn Guild Chest by gaining Glory Points via ranking
            high in:
          </li>
          <ul>
            <li>Daily and Weekly Arena,</li>
            <li>Weekly Honor Duel,</li>
            <li>Daily Dream Realm,</li>
            <li>Weekly Arcane Labyrinth,</li>
          </ul>
          <li>
            After the week ends, the Guild Chests gathered from both sources are
            combined and then split between all guild members. Still, the Guild
            Leader can setup one of three ways of distributing the chests:
          </li>
          <ul>
            <li>
              <strong>Equal Distribution</strong> - the chests will be
              distributed equally between all members,
            </li>
            <li>
              <strong>Activity Distribution</strong> - the chests will be
              distributed based on weekly activity. The played who gathered more
              Activity points for the guild, will get more chests,
            </li>
            <li>
              <strong>Glory Points Distribution</strong> - the chests will be
              distributed based on Glory Points - so the ranks achieved in
              Arena, Honor Duel, Dream Realm and Arcane Lab. The played who
              gathered more Glory Points for the guild, will get more chests.
            </li>
          </ul>
        </ul>
        <SectionHeader title="Battle Drill" />
        <StaticImage
          src="../../../images/afk/generic/beginner_21.webp"
          alt="Guides"
        />
        <p>
          Battle Drills is a competitive available only to Guilds that is very
          similar to the Abyssal Expedition though on a much smaller and more
          manageable scale.
        </p>
        <p>
          The goal of this mode is the progress through the monster-infested map
          by killing scouts, camps and then bosses. You can gather various buffs
          for conquering strongholds that will make your heroes stronger, making
          it easier to push deeper into the enemy territory.
        </p>
        <p>
          Coordination is important if you want to achieve the highest rankings!
        </p>
        <SectionHeader title="Glory Expedition" />
        <StaticImage
          src="../../../images/afk/generic/guild_3.webp"
          alt="Guides"
        />
        <p>
          The Glory Expedition ranking is based on the Glory Points obtained by
          the Guilds in the current Cycle. You can access the Glory Expedition
          screen from the icon in the top right corner on the Guild Screen. A
          single Glory Expedition cycle lasts for <strong>42 days.</strong>
        </p>
        <p>
          As for the Glory Points, you can obtain them from for your guild by
          ranking high in:
        </p>
        <ul>
          <li>Daily and Weekly Arena,</li>
          <li>Weekly Honor Duel,</li>
          <li>Daily Dream Realm,</li>
          <li>Weekly Arcane Labyrinth.</li>
        </ul>
        <p>Here are the rewards you can obtain at the end of the cycle:</p>
        <Table striped bordered responsive className="afk-ascension">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Diamonds</th>
              <th>Guild Medals</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Rank 1-3</td>
              <td className="info">
                <div className="combined">
                  <strong>5 000</strong> <AFKItem name="Diamonds" hideName />
                </div>
              </td>
              <td className="info">12 000</td>
            </tr>
            <tr>
              <td>Rank 4-20</td>
              <td className="info">
                <div className="combined">
                  <strong>4 000</strong> <AFKItem name="Diamonds" hideName />
                </div>
              </td>
              <td className="info">10 000</td>
            </tr>
            <tr>
              <td>Rank 21-60</td>
              <td className="info">
                <div className="combined">
                  <strong>3 000</strong> <AFKItem name="Diamonds" hideName />
                </div>
              </td>
              <td className="info">7 500</td>
            </tr>
            <tr>
              <td>Rank 61-200</td>
              <td className="info">
                <div className="combined">
                  <strong>2 500</strong> <AFKItem name="Diamonds" hideName />
                </div>
              </td>
              <td className="info">6 250</td>
            </tr>
            <tr>
              <td>Rank 201-500</td>
              <td className="info">
                <div className="combined">
                  <strong>2 000</strong> <AFKItem name="Diamonds" hideName />
                </div>
              </td>
              <td className="info">5 000</td>
            </tr>
            <tr>
              <td>Rank 501-1000</td>
              <td className="info">
                <div className="combined">
                  <strong>1 200</strong> <AFKItem name="Diamonds" hideName />
                </div>
              </td>
              <td className="info">3 000</td>
            </tr>
            <tr>
              <td>Rank 1000+</td>
              <td className="info">
                <div className="combined">
                  <strong>1 000</strong> <AFKItem name="Diamonds" hideName />
                </div>
              </td>
              <td className="info">2 500</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesGuilds;

export const Head: React.FC = () => (
  <Seo
    title="Guilds | AFK Journey | Prydwen Institute"
    description="Guild system in AFK Journey explained."
    game="afk"
  />
);
